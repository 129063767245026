import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'ул. Ленина, 28',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: null,
		selectButton: {
			options: [
				{
					value: '//cinemadelux.vip',
					title: 'Нягань',
				},
				{
					value: '//beloyarsky.cinemadelux.vip',
					title: 'Белоярский',
				},
				{
					value: '//ugorsk.cinemadelux.vip',
					title: 'Югорск',
				},
			],
			selectedOptionValue: '//cinemadelux.vip',
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
